import React, { FC, useState } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import arrow from "../../../images/arrow.svg";

interface Props {
  title: string;
  subTitle: string;
  url: string;
  toggle?: () => void;
}
const DropdownLink: FC<Props> = ({ title, subTitle, url, toggle }) => {
  let isMobile = useMediaQuery("(max-width: 991.98px)");
  const [showArrow, setShowArrow] = useState(false);
  const SubMenuLink = styled.div`
    display: flex;
    .link-parent:hover,
    .link-parent:focus {
      background-color: rgba(49, 71, 255, 0.05);
    }
    .title,
    .subTitle {
      max-width: 240px;
      width: 100%;
    }
    .arrow {
      transform: translate(0, -50%);
    }
  `;
  return (
    <SubMenuLink>
      <div className="flex flex-row justify-start bg-transparent flex-1">
        <div
          className="link-parent relative flex flex-1 p-3 -ml-3 md:-ml-2.5 md:-mr-2.5 rounded-lg "
          onMouseEnter={isMobile ? undefined : () => setShowArrow(true)}
          onMouseLeave={isMobile ? undefined : () => setShowArrow(false)}
        >
          <Link
            to={url}
            className="title flex-col justify-start font-Inter text-blue-darken text-sm font-medium"
            onClick={toggle ? toggle : () => {}}
          >
            {showArrow || isMobile ? (
              <img
                className="arrow absolute top-8 right-3"
                src={arrow}
                width={24}
                height={24}
                alt="arrow"
              />
            ) : null}
            {title}
            <p className="subTitle font-Inter text-blue-pale text-sm font-normal">
              {subTitle}
            </p>
          </Link>
        </div>
      </div>
    </SubMenuLink>
  );
};

export default DropdownLink;
