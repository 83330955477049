import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Facebook from "./facebook";
import Twitter from "./twitter";
import socialImage from "../../static/images/large_cover.png";
// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

interface Props {
  title: string;
  description?: string;
  banner?: string;
  pathname: string;
}

const Seo: FC<Props> = ({ title, description, banner, pathname }) => {
  const { site } = useStaticQuery(query);

  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      // defaultBanner,
      // headline,
      siteLanguage,
      ogLanguage,
      // author,
      twitter,
      facebook,
    },
  } = site;
  let url = siteUrl;
  if (url.endsWith("/")) {
    url = url.substring(0, url.length - 1);
  }
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${url}${banner || socialImage}`,
    url: `${url}${pathname || ""}`,
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="title" content={seo.title} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={socialImage} />
        <meta
          name="keywords"
          content="Acquire, funds, Digital, wallets, fraud, indemnification, FinTech, crypto, neobanks, financial, crimes, identity, funding, account, takeovers, fraud prevention, device intelligence, behaviour biometrics, aml, crypto exchange"
        ></meta>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={"https://www.sardine.ai" + socialImage}
        title={seo.title.replace("Sardine - ", "")}
        type="website"
        url={seo.url}
        locale={ogLanguage}
        name={"sardine.ai"}
      />
      <Twitter
        type="summary_large_image"
        title={seo.title}
        image={"https://www.sardine.ai" + socialImage}
        desc={seo.description}
        username={twitter}
        site={siteUrl}
      />
    </>
  );
};

export default Seo;

const query = graphql`
  query Seo {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`;
