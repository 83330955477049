import React, { FC } from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

interface Props {
  data: {
    type: string;
    items: {
      title: string;
      link: string;
      gatsbyLink: boolean;
      hint?: string;
      subItems?: {
        text: string;
        link?: string;
        hint?: string;
      }[];
    }[];
  };
}
const blockWrapper = css`
  .hint {
    font-size: 11px;
    line-height: 11px;
    height: 19px;
  }
`;
const FooterBlock: FC<Props> = ({ data }) => {
  return (
    <div className="footer-block mb-8" css={blockWrapper}>
      <p className="type text-sm font-medium text-blue-pale mb-3 uppercase">
        {data.type}
      </p>
      {data.items.map((item, index) => (
        <div key={index} className="flex flex-col justify-between">
          {
            <div className="font-Inter links-parent mb-5">
              <div className="flex flex-row flex-nowrap justify-between md:justify-start">
                {item.gatsbyLink ? (
                  <Link
                    to={item.link}
                    className="flex flex-row flex-nowrap justify-between text-sm font-medium text-blue-accent mb-2 capitalize"
                  >
                    {item.title}
                  </Link>
                ) : (
                  <a
                    href={item.link}
                    target="_blank"
                    className="flex flex-row flex-nowrap justify-between text-sm font-medium text-blue-accent mb-2 capitalize"
                  >
                    {item.title}
                  </a>
                )}
                {item.hint && (
                  <p className="hint bg-blue-border font-medium text-blue-pale py-1 px-1.5 rounded ml-1.5">
                    {item.hint}
                  </p>
                )}
              </div>
              {item.subItems &&
                item.subItems.map((item, i) => (
                  <div key={i} className="flex flex-col justify-between">
                    <div className="flex flex-row flex-nowrap justify-between md:justify-start">
                      {item.link ? 
                        (<AnchorLink to={item.link}>
                          <p className="text-sm font-normal text-blue-pale mb-2 mr-2">
                            {item.text}
                          </p>
                        </AnchorLink>) : 
                        (<p className="text-sm font-normal text-blue-pale mb-2 mr-2">
                          {item.text}
                        </p>)}
                      {item.hint && (
                        <p className="hint bg-blue-border font-medium text-blue-pale py-1 px-1.5 rounded">
                          {item.hint}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          }
        </div>
      ))}
    </div>
  );
};

export default FooterBlock;
