import React, { FC, useState } from "react";
import { Link, navigate } from "gatsby";
import { css } from "@emotion/react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import Dropdown from "../dropdown/dropdown";
import DropdownMenu from "../dropdownMenu/dropdownMenu";
import logo from "../../images/logo.svg";
import toggleIcon from "../../images/toggle.svg";
import toggleClose from "../../images/close-blue.svg";

interface Props {}
const Navbar: FC<Props> = () => {
  let isMobile = useMediaQuery("(max-width: 991.98px)");
  const productData = [
    {
      type: "Platform",
      hint: "Comprehensive suite of API’s for payments, fraud, and compliance.",
      list: [
        {
          title: "Payments",
          subTitle: "Add instant payments flows",
          url: "/payments",
        },
        {
          title: "Fraud",
          subTitle: "Safeguard customer funds",
          url: "/fraud",
        },
        {
          title: "Compliance",
          subTitle: "Streamline operations for scale",
          url: "/compliance",
        },
      ],
    },
    {
      type: "Teams",
      hint: "Real-time visibiliby and fine-grained controls for internal fraud and compliance teams. ",
      list: [
        {
          title: "Sardine Tools",
          subTitle: "AI-based automatic fraud scoring and SAR case management",
          url: "/tools",
        },
      ],
    },
  ];
  const linkClass =
    "text-blue-darken text-base font-medium mr-10 py-8 hover:text-blue-accent leading-loose md:pt-0 md:pb-5 md:mr:auto md:mb-5 md:border-b md:border-solid md:border-blue-form md:text-lg md:font-semibold md:text-blue-accent md:mr-0 outline-none";
  const [toggle, setToggle] = useState(false);
  return (
    <div className="section-wrapper layout-width md:bg-blue-footer">
      <div className="flex flex-row justify-between h-24 w-full relative md:flex-cols md:-mx-4 ">
        <div className="flex flex-row justify-between h-24 my-auto mr-24 md:z-50 md:flex-1 md:ml-4 md:-mr-4 md:bg-blue-footer">
          <div className="flex flex-col justify-center">
            <Link to="/">
              <img src={logo} alt="logo" width={133} height={38} />
            </Link>
          </div>
          <div
            className="flex-col justify-center hidden md:flex pl-5 cursor-pointer"
            onClick={() => setToggle(!toggle)}
            onChange={() => {}}
            aria-hidden="true"
          >
            {toggle ? (
              <img src={toggleClose} width={17} height={17} alt="close" />
            ) : (
              <img src={toggleIcon} width={24} height={16} alt="toggle" />
            )}
          </div>
        </div>
        <div
          className={`nav-wrapper flex-row justify-between flex-1 flex-grow flex-shrink md:fixed md:flex-col md:justify-start md:min-h-screen md:bg-blue-footer md:w-full md:pl-4 md:pr-4 md:pt-32 md:pb-32 md:max-h-screen md:overflow-y-scroll lg:flex xl:flex xxl:flex md:transition-all md:ease-in-out md:duration-500 md:block ${
            isMobile
              ? toggle
                ? "md:pointer-events-auto md:z-40 md:opacity-100"
                : "md:pointer-events-none md:z-0 md:opacity-0"
              : "md:pointer-events-none md:z-0 md:opacity-0"
          }`}
        >
          <nav className="flex flex-row justify-center h-full ml-3 mr-auto md:flex-col md:justify-start md:ml-auto md:w-full md:h-auto">
            <Dropdown
              dropdownText="Products"
              linkClass={linkClass}
              dropColor="blue"
              toggle={() => setToggle(!toggle)}
            >
              <DropdownMenu menuData={productData} />
            </Dropdown>
            <Link
              className={linkClass}
              to="/developers"
              onClick={() => setToggle(!toggle)}
            >
              Developers
            </Link>
            <Link
              className={linkClass}
              to="/company"
              onClick={() => setToggle(!toggle)}
            >
              Company
            </Link>
            <Link
              className={linkClass + " md:mb-16"}
              css={css`
                border-bottom: unset !important;
              `}
              to="/careers"
              onClick={() => setToggle(!toggle)}
            >
              Careers
            </Link>
          </nav>
          {isMobile ? (
            <button
              className="font-Inter btn-parent bg-blue-accent w-full h-12 text-white text-base font-semibold ml-0 px-8 rounded-2xl md:text-base md:font-semibold hover:bg-blue-hover focus:bg-blue-darken md:max-w-sm md:mr-auto md:ml-auto md:block"
              onClick={() => {
                navigate("/contact", { state: { source: "book_demo" } });
                setToggle(!toggle);
              }}
            >
              Book a demo
            </button>
          ) : null}
          <button
            className="font-Inter button-nav bg-transparent text-blue-accent text-sm font-medium w-36 h-10 ml-3.5 my-auto px-6 border-2 divide-solid border-blue-accent rounded-2xl whitespace-nowrap outline-none shadow-none md:w-full md:h-12 md:text-base md:font-semibold md:mt-8 md:mb-10  hover:text-blue-hover focus:text-blue-darken  hover:border-blue-hover focus:border-blue-darken md:max-w-sm md:mr-auto md:ml-auto md:block"
            onClick={() => {
              navigate("/contact");
              setToggle(!toggle);
            }}
          >
            Contact sales
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
