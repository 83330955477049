import { css } from "@emotion/react";

const FooterWrapper = css`
  .data-footer {
    min-height: 535px;
    width: 100%;
  }
  .third-col .links-parent,
  .second-col .links-parent {
    margin-bottom: 0;
  }
`;

export { FooterWrapper };
