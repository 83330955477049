import { css } from "@emotion/react";
import arrowBlue from "../../images/dropdown_down_blue.svg";
import arrowDarken from "../../images/dropdown_down_black.svg";
import media from "../breakpoints";

const dropdownLink = css`
  z-index: 999;
  .arrow-parent {
    &::after {
      content: "";
      background: url(${arrowBlue}) center/cover no-repeat;
      position: absolute;
      display: block;
      top: 15px;
      right: 0;
      width: 10px;
      height: 6px;
      cursor: pointer;
      ${media("md")} {
        width: 11px;
      }
    }
    &.opened {
      ${media("md")} {
        top: 30px;
        right: -10px;
        transform: rotateZ(180deg);
      }
    }
    &.arrow-darken::after {
      background: url(${arrowDarken}) center/cover no-repeat;
    }
  }
  .arrow-darken::after {
    background: url(${arrowDarken}) center/cover no-repeat;
  }
`;
export { dropdownLink };
