import React, { FC } from "react";
import { css } from "@emotion/react";
import DropdownLink from "../dropdown/dropdownLink/dropdownLink";
import media from "../breakpoints";

interface Props {
  menuData: Array<{
    type: string;
    hint: String;
    list: Array<{
      title: string;
      subTitle: string;
      url: string;
    }>;
  }>;
  toggle?: () => void;
}
const DropdownMenu: FC<Props> = ({ menuData, toggle }) => {
  const shadow = css`
    box-shadow: 0px 23.1429px 25px -15.4286px rgba(13, 40, 59, 0.2);
    ${media("md")} {
      box-shadow: unset;
    }
  `;
  const cardSize = css`
    max-width: 706px;
    min-height: 400px;
    margin: 0 auto;
    .type {
      max-width: 265px;
      ${media("md")} {
        max-width: 100%;
      }
    }
    .first-hint {
      max-width: 280px;
      ${media("md")} {
        max-width: 100%;
      }
    }
    .hint {
      max-width: 307px;
      ${media("md")} {
        max-width: 100%;
      }
    }
  `;
  return (
    <div css={cardSize}>
      <div
        className="rounded-2xl bg-white border border-solid border-blue-border md:rounded-0 md:bg-transparent md:border-0"
        css={shadow}
      >
        <div className="relative w-full h-full flex flex-row justify-between p-10 md:p-0 md:flex-col">
          {menuData.map((items, index) => (
            <div
              key={index}
              className="h-full md:border-b md:border-solid pl-4 md:border-blue-border md:pt-5 md:pb-7 md:ml-0"
            >
              <div className="flex flex-col justify-start mb-10 md:mb-4">
                <p className="type font-Inter text-blue-accent text-base font-semibold mb-4 md:mb-2.5">
                  {items.type}
                </p>
                <p
                  className={`${
                    index === 0 ? "first-hint" : "hint"
                  } font-Inter text-blue-pale text-sm font-normal`}
                >
                  {items.hint}
                </p>
              </div>
              <div className="font-Inter flex flex-col justify-start">
                {items.list.map((item, i) => (
                  <DropdownLink
                    key={i}
                    title={item.title}
                    subTitle={item.subTitle}
                    url={item.url}
                    toggle={toggle}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
