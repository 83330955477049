import React, { FC } from "react";
import { Helmet } from "react-helmet";

interface Props {
  type?: string;
  username?: string;
  title?: string;
  desc?: string;
  image?: string;
  site?: string;
}

const Twitter: FC<Props> = ({ type, username, title, desc, image, site }) => (
  <Helmet>
    {username && <meta name="twitter:creator" content={username} />}
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={desc} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={desc} />
    <meta name="twitter:site" content={site} />
  </Helmet>
);

export default Twitter;
