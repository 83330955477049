import React, { FC } from "react";
import { Link } from "gatsby";
import { FooterWrapper } from "./footer.styles";
import FooterBlock from "../footerBlock/footerBlock";
import logo from "../../images/logo.svg";
import medium from "../../images/footer/medium.svg";
import linkedin from "../../images/footer/linkedin.svg";
import twitter from "../../images/footer/twitter.svg";

interface Props {
  //   title: string;
}
const Footer: FC<Props> = () => {
  const footerData = {
    platform: {
      type: "Platform",
      items: [
        {
          title: "Payments",
          link: "/payments",
          gatsbyLink: true,
          subItems: [
            {
              text: "Fraud Indemnification",
            },
            {
              text: "Instant Bank ACH",
            },
            {
              text: "Bank ACH to Crypto",
            },
          ],
        },
        {
          title: "Fraud",
          link: "/fraud",
          gatsbyLink: true,
          subItems: [
            {
              text: "Sign up fraud",
            },
            {
              text: "Payments Fraud",
            },
            {
              text: "Device Intelligence",
            },
          ],
        },
        {
          title: "Compliance",
          link: "/compliance",
          gatsbyLink: true,
          subItems: [
            {
              text: "AML Transaction Monitoring",
            },
            {
              text: "Sanctions and Adverse Media",
            },
            {
              text: "KYC Document Verification",
            },
          ],
        },
      ],
    },
    company: {
      type: "COMPANY",
      items: [
        {
          title: "Team",
          link: "/company",
          gatsbyLink: true,
        },
        {
          title: "Careers",
          link: "/careers",
          gatsbyLink: true,
          hint: "We’re hiring!",
        },
        {
          title: "Blog",
          link: "https://medium.com/sardineai",
          gatsbyLink: false,
        },
      ],
    },
    developers: {
      type: "Developers",
      items: [
        {
          title: "API Documentation",
          link: "https://docs.sardine.ai/",
          gatsbyLink: false,
        },
      ],
    },
    teams: {
      type: "teams",
      items: [
        {
          title: "Sardine Tools",
          link: "/tools",
          gatsbyLink: true,
        },
      ],
    },
    resources: {
      type: "RESOURCES",
      items: [
        {
          title: "Support",
          link: "/support",
          gatsbyLink: true,
        },
        {
          title: "Contact",
          link: "/contact",
          gatsbyLink: true,
        },
        {
          title: "Privacy policy",
          link: "/privacy",
          gatsbyLink: true,
        },
        {
          title: "GDPR",
          link: "/gdpr",
          gatsbyLink: true,
        },
        {
          title: "Terms & conditions",
          link: "/terms",
          gatsbyLink: true,
        },
      ],
    },
  };
  return (
    <section css={FooterWrapper}>
      <div className="bg-blue-footer w-full md:pt-0 pt-16">
        <div className="wrapper-parent flex flex-col justify-between">
          <div className="data-footer layout-width flex flex-row justify-between md:block">
            <div className="flex flex-col justify-center mb-auto mr-16 md:mb-16">
              <Link to="/">
                <img src={logo} alt="logo" width={133} height={38} />
              </Link>
            </div>
            <div className="flex flex-row justify-around flex-1 ml-2.5 md:ml-0 md:pr-8 md:justify-start md:flex-wrap">
              <div className="font-Inter first-col md:w-full">
                <FooterBlock data={footerData.platform} />
              </div>
              <div className="font-Inter second-col md:mr-16">
                <FooterBlock data={footerData.company} />
                <FooterBlock data={footerData.developers} />
                <FooterBlock data={footerData.teams} />
              </div>
              <div className="font-Inter third-col">
                <FooterBlock data={footerData.resources} />
              </div>
            </div>
          </div>
          <div className="basic-footer h-20 bg-blue-darken flex flex-col justify-center md:h-36">
            <div className="layout-width flex flex-row justify-between w-full md:py-8 md:flex-1 md:flex-col md:justify-around">
              <span className="font-Inter text-blue-pale text-sm font-normal ">
              382 NE 191st St, #58243 Miami, Florida 33179-3899 US © 2022
                Sardine. All rights reserved.
              </span>
              <div className="social flex flex-row justify-between w-40">
                <a
                  href="https://medium.com/sardineai"
                  target="_blank"
                  className="my-auto"
                >
                  <img src={medium} width="24" height="14" alt="medium" />
                </a>
                <a
                  href="https://www.linkedin.com/company/sardineai"
                  target="_blank"
                  className="my-auto"
                >
                  <img src={linkedin} width="20" height="20" alt="linkedin" />
                </a>
                <a
                  href="https://twitter.com/sardineai"
                  target="_blank"
                  className="my-auto"
                >
                  <img src={twitter} width="22" height="18" alt="twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
