import React, { FC, ReactNode, useState, useEffect } from "react";
import "./layout.css";
import Header from "./header/header";
import Footer from "./footer/footer";
import { v4 as uuidv4 } from "uuid";

interface Props {
  children: ReactNode;
}

const Layout: FC<Props> = ({ children }) => {
  const isDev = false;
  const sardineHost = isDev ? "api.dev.sardine.ai" : "api.sardine.ai";
  const pixelHost = isDev ? "p.dev.sardine.ai" : "p.sardine.ai";
  const clientId = "da9e843a-f2c5-4de2-bf63-a31a45c2eac6";
  const [sessionKey, setSessionKey] = useState("");
  const [showTop, setShowTop] = useState(true);

  useEffect(() => {
    function loadScript() {
      let _key = localStorage.getItem("sessionKey");
      const time = localStorage.getItem("sessionTime") || "0";
      const currentTime = Math.floor(Date.now() / 1000);

      if (_key === null || currentTime - parseInt(time) > 1800) {
        _key = uuidv4();
        setSessionKey(_key);
        localStorage.setItem("sessionKey", _key);
        localStorage.setItem("sessionTime", currentTime.toString());
      } else {
        setSessionKey(_key);
      }

      const loader = document.createElement("script");
      loader.type = "text/javascript";
      loader.async = true;
      loader.src = `https://${sardineHost}/assets/loader.min.js`;
      loader.onload = function () {
        (window as any)._Sardine.createContext({
          clientId: clientId,
          sessionKey: _key,
          userIdHash: "12345",
          flow: window.location.pathname,
          environment: isDev ? "development" : "production",
          parentElement: document.body,
          onDeviceResponse: function (data: any) {
            console.log(`sardine's deviceID is ${data.deviceId}`);
          },
        });
      };
      const s = document.getElementsByTagName("script")[0];
      s.parentNode && s.parentNode.insertBefore(loader, s);
    }

    if (sessionKey.length === 0) {
      loadScript();
    }
  }, [clientId, sessionKey, isDev, sardineHost]);
  return (
    <>
      <div className="layout-container font-Inter">
        {typeof window === `undefined` ? null : (
          <noscript>
            <img
              alt={""}
              src={`https://${pixelHost}/v1/a.png?clientId=${clientId}&sessionKey=${sessionKey}&flow=${window.location.pathname}&ns=1`}
              style={{ display: "none" }}
            />
          </noscript>
        )}
        <Header showTop={showTop} setShowTop={setShowTop} />
        <div className={showTop ? "md:pt-14" : ""}>{children}</div>

        <Footer />
      </div>
      <script src="https://tag.clearbitscripts.com/v1/pk_08f1d46e2e027179c77e777f65ad07b2/tags.js"></script>
    </>
  );
};

export default Layout;
