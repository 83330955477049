import React, { FC } from "react";
import { HeaderWrapper, Link } from "./header.styles";
import Navbar from "../navbar/navbar";
import close from "../../images/close.svg";

interface Props {
  showTop: boolean;
  setShowTop: (arg0: boolean) => void;
}
const Header: FC<Props> = ({ showTop, setShowTop }) => {
  return (
    <HeaderWrapper className="section-wrapper min-w-full md:fixed z-50">
      {showTop ? (
        <div className="bg-blue-accent h-max w-full">
          <div className="layout-width relative flex flex-row justify-center h-full">
            <div className="flex flex-col justify-center h-full">
              <p className="up-hint text-base text-white font-normal content-center m-auto font-Inter pt-3 pb-3">
                Sardine raises
                <span className="font-semibold font-Inter">
                  {" "}
                  $51.5M Series B
                </span>{" "}
                led by Andreessen Horowitz 🎉
                <span className="font-Inter">
                  {" "}
                  <Link target={"_blank"} href="https://www.businesswire.com/news/home/20220919005836/en/Sardine-Raises-51.5M-Series-B-Led-by-Andreessen-Horowitz">Learn More</Link>
                </span>{" "}
              </p>
            </div>
            <div className="flex flex-col justify-center h-full absolute top-0 right-0 lg:right-8 md:right-5">
              <button
                className="font-Inter w-4 h-4"
                onClick={() => setShowTop(false)}
              >
                <img width="16" height="16" src={close} alt="close" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <Navbar />
    </HeaderWrapper>
  );
};

export default Header;
