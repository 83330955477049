import { useState, useEffect } from "react";

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener); // addListener method if you want to support Safari < 14
    return () => media.removeListener(listener); // removeListener method if you want to support Safari < 14
  }, [matches, query]);

  return matches;
}
