import styled from "@emotion/styled";
import media from "../breakpoints";
const HeaderWrapper = styled.header`
  .up-hint {
    ${media("md")} {
      font-size: 13px;
      max-width: 266px;
      margin: auto;
    }
  }
  .nav-wrapper::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }
  .nav-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: unset;
  }
`;

const Link = styled.a`
  text-decoration: underline;
`;

export { HeaderWrapper, Link };
