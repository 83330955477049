import React, {
  FC,
  ReactNode,
  useState,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import { dropdownLink } from "./dropdown.styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";

interface Props {
  dropdownText: string;
  linkClass: string;
  children: ReactNode;
  dropColor: string;
  toggle: () => void;
}
const Dropdown: FC<Props> = ({
  children,
  dropdownText,
  linkClass,
  dropColor,
  toggle,
}) => {
  let isMobile = useMediaQuery("(max-width: 991.98px)");
  const [tabIndex, setTabIndex] = useState(1);
  const handleShow = () => setTabIndex(0);
  const handleClose = () => setTimeout(() => setTabIndex(1), 0);
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (isValidElement(child)) {
      return cloneElement(child, { toggle });
    }
    return child;
  });
  return (
    <div
      css={dropdownLink}
      className={`${linkClass} font-Inter flex relative md:flex-col`}
      onMouseOver={isMobile ? () => {} : handleShow}
      onMouseLeave={isMobile ? () => {} : handleClose}
      onClick={
        isMobile ? (tabIndex === 0 ? handleClose : handleShow) : () => {}
      }
      tabIndex={tabIndex}
    >
      <span className="cursor-pointer flex flex-row justify-between">
        {dropdownText}
        <span
          className={`${
            dropColor === "darken"
              ? "arrow-parent arrow-darken"
              : "arrow-parent"
          } ${
            tabIndex === 0 ? " opened" : ""
          } font-Inter relative w-6 h-full cursor-pointer md:h-1.5`}
        ></span>
      </span>

      {tabIndex === 0 || isMobile ? (
        <div
          className={`dropdown-menu font-Inter absolute left-0 top-full w-max md:w-full h-full md:transition-all md:ease-linear md:duration-200  ${
            tabIndex === 0
              ? "md:opacity-100 md:max-h-full"
              : "md:opacity-0 md:max-h-0"
          }
          `}
          aria-hidden="true"
        >
          <div
            className={
              tabIndex === 0
                ? "md:pointer-events-auto md:w-screen md:bg-blue-footer md:-ml-4 md:pr-8 md:pl-4"
                : "md:pointer-events-none md:w-screen md:bg-blue-footer md:-ml-4 md:pr-8 md:pl-4"
            }
          >
            {childrenWithProps}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
